/* Extra small devices (portrait phones, less than 576px)  */
@media (max-width: 575.98px) {  
    .lzText {
        color: white;
        font-family: 'Neo Bold';
        font-size: 13px;
    }

    .copyRightText {
        color: white;
        font-family: 'Neo Light';
        font-size: 13px;
    }

    .beeContactInfo {
        color: white;
        font-family: 'Neo Light';
        font-size: 13px;
    }

    .contactIcon1 {
        height: 20px;
        width: 20px;
        margin-left: 8px;
    }
    
    .contactIcon {
        height: 20px;
        width: 20px;
    }
    
    .contactezText {
        font-family: 'Neo Bold';
        font-size: 30px;
        color: #F37121;
    }

    .hrFooter {
        border-top: 1px solid #F37121;
        width: 50%;
        /* margin-left: -25px; */
        /* margin-right: 15px; */
    }

    .socialLogo {
        height: auto;
        width: 100%;
        margin-right: 20px;
    }

    .beeLogo {
        width: 120px;
        height: auto;
    }

    .footerContainer {
        padding: 20px 0px 0px 0px;
        /* padding: 25px; */
        min-width: 100%;
        background-image: url('../../assets/images/footer/backFooter.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: 80%;
        /* background-size: 100% 100%; */
        /* height: 520px; */
        /* background-color: purple; */
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {  
    .lzText {
        color: white;
        font-family: 'Neo Bold';
        font-size: 15px;
    }

    .copyRightText {
        color: white;
        font-family: 'Neo Light';
        font-size: 15px;
    }

    .beeContactInfo {
        color: white;
        font-family: 'Neo Light';
        font-size: 15px;
    }

    .contactIcon1 {
        height: 20px;
        width: 20px;
        margin-left: 8px;
    }
    
    .contactIcon {
        height: 20px;
        width: 20px;
    }
    
    .contactezText {
        font-family: 'Neo Bold';
        font-size: 35px;
        color: #F37121;
    }

    .hrFooter {
        border-top: 1px solid #F37121;
        width: 50%;
        /* margin-left: -25px; */
        /* margin-right: 15px; */
    }

    .socialLogo {
        height: auto;
        width: 25px;
        margin-right: 20px;
    }

    .beeLogo {
        width: 120px;
        /* width: 15%; */
        height: auto;
    }

    .footerContainer {
        padding: 20px 0px 0px 0px;
        /* padding: 25px; */
        min-width: 100%;
        background-image: url('../../assets/images/footer/backFooter.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: 90%;
        /* background-size: 100% 100%; */
        /* height: 520px; */
        /* background-color: purple; */
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    .lzText {
        color: white;
        font-family: 'Neo Bold';
        font-size: 15px;
    }

    .copyRightText {
        color: white;
        font-family: 'Neo Light';
        font-size: 15px;
    }

    .beeContactInfo {
        color: white;
        font-family: 'Neo Light';
        font-size: 15px;
    }

    .contactIcon1 {
        height: 20px;
        width: 20px;
        margin-left: 8px;
    }
    
    .contactIcon {
        height: 20px;
        width: 20px;
    }
    
    .contactezText {
        font-family: 'Neo Bold';
        font-size: 35px;
        color: #F37121;
    }

    .hrFooter {
        border-top: 1px solid #F37121;
        width: 50%;
        /* margin-left: -25px; */
        /* margin-right: 15px; */
    }

    .socialLogo {
        height: auto;
        width: 30px;
        margin-right: 20px;
    }

    .beeLogo {
        width: 120px;
        /* width: 15%; */
        height: auto;
    }

    .footerContainer {
        padding: 20px 0px 0px 0px;
        /* padding: 25px; */
        min-width: 100%;
        background-image: url('../../assets/images/footer/backFooter.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: 90%;
        /* background-size: 100% 100%; */
        /* height: 520px; */
        /* background-color: purple; */
    }
 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {  
    .lzText {
        color: white;
        font-family: 'Neo Bold';
        font-size: 15px;
    }

    .copyRightText {
        color: white;
        font-family: 'Neo Light';
        font-size: 15px;
    }

    .beeContactInfo {
        color: white;
        font-family: 'Neo Light';
        font-size: 15px;
    }

    .contactIcon1 {
        height: 20px;
        width: 20px;
        margin-left: 8px;
    }
    
    .contactIcon {
        height: 20px;
        width: 20px;
    }
    
    .contactezText {
        font-family: 'Neo Bold';
        font-size: 35px;
        color: #F37121;
    }

    .hrFooter {
        border-top: 1px solid #F37121;
        width: 50%;
        /* margin-left: -25px; */
        /* margin-right: 15px; */
    }

    .socialLogo {
        height: auto;
        width: 30px;
        margin-right: 20px;
    }

    .beeLogo {
        width: 120px;
        /* width: 6%; */
        height: auto;
    }

    .footerContainer {
        padding: 20px 0px 0px 0px;
        /* padding: 25px; */
        min-width: 100%;
        background-image: url('../../assets/images/footer/backFooter.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: 90%;
        /* background-size: 100% 100%; */
        /* height: 520px; */
        /* background-color: purple; */
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

    .lzText {
        color: white;
        font-family: 'Neo Bold';
        font-size: 15px;
    }

    .copyRightText {
        color: white;
        font-family: 'Neo Light';
        font-size: 15px;
    }

    .beeContactInfo {
        color: white;
        font-family: 'Neo Light';
        font-size: 15px;
    }

    .contactIcon1 {
        height: 20px;
        width: 20px;
        margin-left: 8px;
    }
    
    .contactIcon {
        height: 20px;
        width: 20px;
    }
    
    .contactezText {
        font-family: 'Neo Bold';
        font-size: 35px;
        color: #F37121;
    }

    .hrFooter {
        border-top: 1px solid #F37121;
        width: 50%;
        /* margin-left: -25px; */
        /* margin-right: 15px; */
    }

    .socialLogo {
        height: auto;
        width: 30px;
        margin-right: 20px;
    }

    .beeLogo {
        width: 140px;
        /* width: 6%; */
        height: auto;
    }

    .footerContainer {
        padding: 20px 0px 0px 0px;
        /* padding: 25px; */
        min-width: 100%;
        background-image: url('../../assets/images/footer/backFooter.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        /* height: 520px; */
        /* background-color: purple; */
    }

}