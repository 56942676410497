@font-face {
  font-family: 'Neo Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Neo Bold'), url('../src/assets/fonts/NeoBold.woff') format('woff');
}

@font-face {
  font-family: 'Neo Light';
  font-style: normal;
  font-weight: normal;
  src: local('Neo Light'), url('../src/assets/fonts/NeoLight.woff') format('woff');
}

@font-face {
  font-family: 'Neo Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Neo Regular'), url('../src/assets/fonts/NeoRegular.woff') format('woff');
}
