
.payDesc {
  color: black;
  font-size: 20px;
  font-family: 'Neo Bold';
  margin-bottom: 15px;
}

.payName {
  color: black;
  font-size: 18px;
  font-weight: 300;
  font-family: 'Neo Light';
  margin-bottom: 15px;
}

.payTitle {
  font-size: 25px;
  color: #314294;
  font-family: 'Neo Bold';
  margin-bottom: 15px;
}

.btnPopup {
  width: 30%;
  border-radius: 10rem;
  background-color: #F37121;
  color: white;
  border: 1px solid #F37121;
  font-family: 'Neo Light';
  padding: 4px 0px 4px 0px;
  cursor: pointer;
  transition: all 600ms ease-in-out 0s;
}

.btnPopup:hover {
  border-radius: 10rem;
  background-color: white;
  color: #F37121;
  border: 1px solid #F37121;
  transition: all 600ms ease-in-out 0s;
}

/* width */
.scrollableLeft::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.scrollableLeft::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px gray; 
  border-radius: 10px;
}
 
/* Handle */
.scrollableLeft::-webkit-scrollbar-thumb {
  background: #314294; 
  border-radius: 20px;
}

/* Handle on hover */
.scrollableLeft::-webkit-scrollbar-thumb:hover {
  background: #314294; 
}
  
.scrollableRight::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.scrollableRight::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px gray; 
  border-radius: 10px;
}
 
/* Handle */
.scrollableRight::-webkit-scrollbar-thumb {
  background: #314294; 
  border-radius: 20px;
}

/* Handle on hover */
.scrollableRight::-webkit-scrollbar-thumb:hover {
  background: #314294; 
}

.backimage{
  background: url("../../assets/images/factures/pay-back.png");
  background-size:contain;
  background-position:top;
  background: cover;
  background-repeat: no-repeat; 
  height: 426px;
}
.textBack{
  font-family: 'Neo Bold';
  font-size: 60px;
  line-height: 160px;
  text-align: center;
  color: #fff;
  margin: auto;
}

.containerButFact {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 18px;
    color: #314294;
    font-family: 'Neo Regular';
    font-weight: 200;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
    
/* Hide the browser's default radio button */
.containerButFact input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmarkFact {
  position: absolute;
  top: 6px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #314294;
  background-color: transparent;
}

/* On mouse-over, add a grey background color */
.containerButFact:hover input ~ .checkmarkFact {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.containerButFact input:checked ~ .checkmarkFact {
  background-color: transparent;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmarkFact:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerButFact input:checked ~ .checkmarkFact:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerButFact .checkmarkFact:after {
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #314294;
}


/* @media (max-width: 575.98px) {   */
@media (max-width: 767.98px) {  

  .btn-light:focus {
    color: #ffffff !important;
    background-color: #F37121 !important;
    border-color: #FFB613 !important;
    cursor: pointer;
  }

    .btn-light:active {
      color: #fff !important;
      background-color: #58B795 !important;
      border-color: #fff !important;
      cursor: pointer;
    }

    .submitButton:hover {
      width: 100%;
      border-radius: 10rem;
      background-color: white;
      color: #F37121;
      border: 1px solid #F37121;
      cursor: pointer;
    }

    .submitButton {
      width: 100%;
      border-radius: 10rem;
      background-color: #F37121;
      font-family: 'Neo Light';
      color: white;
      border: 1px solid #F37121;
      cursor: pointer;
    }

    .formRadiosContainer {
      padding-left: 29px;
    }

    .selectAllText {
        color: black;
        font-size: 14px;
        font-family: 'Neo Regular';
        margin-top: 5px;
    }

    /* RIGHT CARD */
      /* Custom Checkbox */
      .butTypeContainer {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 20px;
        font-family: 'Neo Regular';
        color: white;
        font-weight: 200;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      /* Hide the browser's default radio button */
      .butTypeContainer input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      
      /* Create a custom radio button */
      .btnCheckmark {
        position: absolute;
        top: 6px;
        left: 0;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 1px solid #314294;
        background-color: transparent;
      }
      
      /* On mouse-over, add a grey background color */
      .butTypeContainer:hover input ~ .btnCheckmark {
        background-color: #ccc;
      }
      
      /* When the radio button is checked, add a blue background */
      .butTypeContainer input:checked ~ .btnCheckmark {
        background-color: transparent;
      }
      
      /* Create the indicator (the dot/circle - hidden when not checked) */
      .btnCheckmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      /* Show the indicator (dot/circle) when checked */
      .butTypeContainer input:checked ~ .btnCheckmark:after {
        display: block;
      }
      
      /* Style the indicator (dot/circle) */
      .butTypeContainer .btnCheckmark:after {
          top: 4px;
          left: 4px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #314294;
      }

    .paymentTypeTitle {
      font-size: 18px;
      color: black;
      font-family: 'Neo Bold'; 
    }

    .totalText {
      font-size: 18px;
      color: #314294 ;
      font-family: 'Neo Bold' ;
    }
    
    .totalPrice {
      font-size: 18px;
      color: #FFB613 ;
      font-family: 'Neo Regular' ;

    }

    .totalContainer {
        padding: 20px 20px 20px 20px;
        background-color: white;
        margin-left: -50px;
        margin-right: -28px;
    }

    /* DETAILS FACTURES */
      .hrDetails {
        border-top: 1px solid white;
        margin-left: -25px;
        margin-right: 15px;
        width: 100%;
      }

      .detailsFactureTitle {
          font-size: 13px;
          color: white;
          font-family: 'Neo Regular';
        }
        
        .detailsFacturePrice {
          font-size: 13px;
          color: #314294;
          font-family: 'Neo Regular';
        }
        
        .detailsFactureDate {
          font-size: 13px;
          color: #314294;
          font-family: 'Neo Regular';
          margin-top: -10px;

      }
    /* DETAILS FACTURES */

    .rightCardPhone {
      font-size: 18px;
      color: #314294;
      font-family: 'Neo Regular';
    }

    .rightCardTitle {
        font-size: 20px;
        color: #314294;
        font-family: 'Neo Bold';
    }

    .rightFactureContainer {
      background-color: #58B795;
      margin-top: -40px;
      margin-bottom: 50px;
      border-radius: 1rem;
      padding: 28px 28px 28px 16px;
      left: 22px;
      top: -40px;
      /* -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
      -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
      box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);    */
    }
  /* RIGHT CARD */


  /* LEFT CARD */

  .scrollableLeft {
    width: 102.1%;
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px 10px 50px 16px;
  }
  
  .scrollableRight {
    width: 100%;
    height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px 10px 50px 16px;
  }

  .leftFactureContainer {
      z-index: 1; 
      left: 21px;
      background-color: white;
      margin-top: -40px;
      margin-bottom: 50px;
      border-radius: 1rem;
      padding: 28px;
      -webkit-box-shadow: 0px 0px 22px -13px rgba(0,0,0,0.39);
      -moz-box-shadow: 0px 0px 22px -13px rgba(0,0,0,0.39);
      box-shadow: 0px 0px 22px -13px rgba(0,0,0,0.39);   
  }

  .radioFacture {
      padding-left: 7%;
  }

  .factureTitle {
    font-size: 12px;
    color: #314294;
    font-family: 'Neo Bold';
  }

  .total-facture {
      color: #FFB613;
      font-size: 14px;
      font-family: 'Neo Regular';
  }

  .payer-avant {
      font-size: 11px;
      color: #D23030;
      font-family: 'Neo Light';
      float: right;
      text-align: -webkit-right;
  }

  .btn-primary:focus {
      color: #ffffff !important;
      background-color: #58B795 !important;
      border-color: #58B795 !important;
      cursor: pointer;
    }

  .btn-primary:active {
    color: #fff !important;
    background-color: #58B795 !important;
    border-color: #fff !important;
    cursor: pointer;
  }

  .voir-facture-btn:hover {
    width: 100%;
    border-radius: 10rem;
    background-color: white;
    color: #58B795;
    border: 1px solid #58B795;
    cursor: pointer;
  }

  .voir-facture-btn {
    width: 100%;
    border-radius: 10rem;
    background-color: #58B795;
    color: white;
    border: 1px solid #58B795;
    cursor: pointer;
    font-family: 'Neo Light';
    font-size: 13px;
  }
  
  .facture-card {
      background-color: white;
      border-radius: 1rem;
      padding: 10px 28px 10px 28px;
      -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }

  .leftCardMiniTitle {
    font-size: 12px;
    color: black;
    font-family: 'Neo Regular';
  }

  .leftCardTitle {
      font-size: 25px;
      color: #314294;
      font-family: 'Neo Bold';
      margin-left: -15px;
  }

  /* ./LEFT CARD */
 
  .image-pay {
      width: 100%;
      background: rgba(49, 66, 148,1);
  }
}
  
/* Small devices (landscape phones, 576px and up) */
/* @media (min-width: 576px) and (max-width: 767.98px) {  

} */

/* Medium devices (tablets, 768px and up) */
@media (max-width: 991.98px) { 

    .selectAllText {
      color: black;
      font-size: 20px;
      font-family: 'Neo Regular';
    }

    /* RIGHT CARD */
      /* Custom Checkbox */
      .butTypeContainer {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 20px;
        font-family: 'Neo Regular';
        color: white;
        font-weight: 200;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      /* Hide the browser's default radio button */
      .butTypeContainer input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      
      /* Create a custom radio button */
      .btnCheckmark {
        position: absolute;
        top: 6px;
        left: 0;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 1px solid #314294;
        background-color: transparent;
      }
      
      /* On mouse-over, add a grey background color */
      .butTypeContainer:hover input ~ .btnCheckmark {
        background-color: #ccc;
      }
      
      /* When the radio button is checked, add a blue background */
      .butTypeContainer input:checked ~ .btnCheckmark {
        background-color: transparent;
      }
      
      /* Create the indicator (the dot/circle - hidden when not checked) */
      .btnCheckmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      /* Show the indicator (dot/circle) when checked */
      .butTypeContainer input:checked ~ .btnCheckmark:after {
        display: block;
      }
      
      /* Style the indicator (dot/circle) */
      .butTypeContainer .btnCheckmark:after {
          top: 4px;
          left: 4px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #314294;
      }

    .paymentTypeTitle {
      font-size: 18px;
      color: black;
      font-family: 'Neo Bold'; 
    }

    .totalText {
      font-size: 25px;
      color: #314294 ;
      font-family: 'Neo Bold' ;
    }
    
    .totalPrice {
      font-size: 25px;
      color: #FFB613 ;
      font-family: 'Neo Regular' ;

    }

    .totalContainer {
        padding: 20px 0px 20px 0px;
        background-color: white;
        margin-left: -50px;
        margin-right: -28px;
    }

    /* DETAILS FACTURES */
      .hrDetails {
        border-top: 1px solid white;
        margin-left: -25px;
        margin-right: 15px;
        width: 100%;
      }

      .detailsFactureTitle {
          font-size: 13px;
          color: white;
          font-family: 'Neo Regular';
        }
        
        .detailsFacturePrice {
          font-size: 13px;
          color: #314294;
          font-family: 'Neo Regular';
        }
        
        .detailsFactureDate {
          font-size: 13px;
          color: #314294;
          font-family: 'Neo Regular';
          margin-top: -10px;

      }
    /* DETAILS FACTURES */

    .rightCardPhone {
      font-size: 18px;
      color: #314294;
      font-family: 'Neo Regular';
    }

    .rightCardTitle {
        font-size: 20px;
        color: #314294;
        font-family: 'Neo Bold';
    }

    .rightFactureContainer {
      background-color: #58B795;
      margin-top: -40px;
      margin-bottom: 50px;
      border-radius: 1rem;
      padding: 28px 28px 28px 50px;
      left: 31px;
      top: -40px;
      /* -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
      -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
      box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);    */
    }
  /* RIGHT CARD */


  /* LEFT CARD */

  .scrollableLeft {
    width: 102.1%;
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px 0px 50px 0px;
  }
  .scrollableRight {
    width: 100%;
    height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px 10px 50px 16px;
  }

  .leftFactureContainer {
      z-index: 1; 
      left: 30px;
      background-color: white;
      margin-top: -40px;
      margin-bottom: 50px;
      border-radius: 1rem;
      padding: 28px;
      -webkit-box-shadow: 0px 0px 22px -13px rgba(0,0,0,0.39);
      -moz-box-shadow: 0px 0px 22px -13px rgba(0,0,0,0.39);
      box-shadow: 0px 0px 22px -13px rgba(0,0,0,0.39);   
  }

  .radioFacture {
      padding-left: 7%;
  }

  .factureTitle {
    font-size: 12px;
    color: #314294;
    font-family: 'Neo Bold';
  }

  .total-facture {
      color: #FFB613;
      font-size: 14px;
      font-family: 'Neo Regular';
  }

  .payer-avant {
      font-size: 11px;
      color: #D23030;
      font-family: 'Neo Light';
      text-align: -webkit-right;
  }

  .btn-primary:focus {
      color: #ffffff !important;
      background-color: #58B795 !important;
      border-color: #58B795 !important;
      cursor: pointer;
    }

  .btn-primary:active {
    color: #fff !important;
    background-color: #58B795 !important;
    border-color: #fff !important;
    cursor: pointer;
  }

  .voir-facture-btn:hover {
    width: 80%;
    border-radius: 10rem;
    background-color: white;
    color: #58B795;
    border: 1px solid #58B795;
    cursor: pointer;
  }

  .voir-facture-btn {
    width: 80%;
    border-radius: 10rem;
    background-color: #58B795;
    color: white;
    border: 1px solid #58B795;
    cursor: pointer;
    font-family: 'Neo Light';
    font-size: 13px;
  }
  
  .facture-card {
      background-color: white;
      border-radius: 1rem;
      padding: 28px;
      -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); 
  }

  .leftCardMiniTitle {
    font-size: 15px;
    color: black;
    font-family: 'Neo Regular';
  }

  .leftCardTitle {
      font-size: 25px;
      color: #314294;
      font-family: 'Neo Bold';
  }

  /* ./LEFT CARD */
 
  .image-pay {
      width: 100%;
      background: rgba(49, 66, 148,1);
  }
}

/* Large devices (desktops, 992px and up) */
@media (max-width: 1199.98px) {  
      .selectAllText {
        color: black;
        font-size: 20px;
        font-family: 'Neo Regular';
      }

     /* RIGHT CARD */
      /* Custom Checkbox */
      .butTypeContainer {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 20px;
        font-family: 'Neo Regular';
        color: white;
        font-weight: 200;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      /* Hide the browser's default radio button */
      .butTypeContainer input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      
      /* Create a custom radio button */
      .btnCheckmark {
        position: absolute;
        top: 6px;
        left: 0;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 1px solid #314294;
        background-color: transparent;
      }
      
      /* On mouse-over, add a grey background color */
      .butTypeContainer:hover input ~ .btnCheckmark {
        background-color: #ccc;
      }
      
      /* When the radio button is checked, add a blue background */
      .butTypeContainer input:checked ~ .btnCheckmark {
        background-color: transparent;
      }
      
      /* Create the indicator (the dot/circle - hidden when not checked) */
      .btnCheckmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      /* Show the indicator (dot/circle) when checked */
      .butTypeContainer input:checked ~ .btnCheckmark:after {
        display: block;
      }
      
      /* Style the indicator (dot/circle) */
      .butTypeContainer .btnCheckmark:after {
          top: 4px;
          left: 4px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #314294;
      }

    .paymentTypeTitle {
      font-size: 18px;
      color: black;
      font-family: 'Neo Bold'; 
    }

    .totalText {
      font-size: 25px;
      color: #314294 ;
      font-family: 'Neo Bold' ;
    }
    
    .totalPrice {
      font-size: 25px;
      color: #FFB613 ;
      font-family: 'Neo Regular' ;

    }

    .totalContainer {
        padding: 20px 0px 20px 0px;
        background-color: white;
        margin-left: -25px;
        margin-right: -28px;
    }

    /* DETAILS FACTURES */
      .hrDetails {
        border-top: 1px solid white;
        margin-left: -25px;
        margin-right: 15px;
        width: 100%;
      }

      .detailsFactureTitle {
          font-size: 13px;
          color: white;
          font-family: 'Neo Regular';
        }
        
        .detailsFacturePrice {
          font-size: 13px;
          color: #314294;
          font-family: 'Neo Regular';
        }
        
        .detailsFactureDate {
          font-size: 13px;
          color: #314294;
          font-family: 'Neo Regular';
          margin-top: -10px;

      }
    /* DETAILS FACTURES */

    .rightCardPhone {
      font-size: 18px;
      color: #314294;
      font-family: 'Neo Regular';
    }

    .rightCardTitle {
        font-size: 20px;
        color: #314294;
        font-family: 'Neo Bold';
    }

    .rightFactureContainer {
      background-color: #58B795;
      margin-top: -40px;
      margin-bottom: 50px;
      border-radius: 1rem;
      padding: 28px 28px 28px 50px;
      /* -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
      -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
      box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);    */
    }
  /* RIGHT CARD */


  /* LEFT CARD */

  .scrollableLeft {
    width: 102.1%;
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px 0px 50px 0px;
  }
  .scrollableRight {
    width: 100%;
    height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px 10px 50px 16px;
  }
  
  .leftFactureContainer {
      z-index: 1; 
      left: 30px;
      background-color: white;
      margin-top: -40px;
      margin-bottom: 50px;
      border-radius: 1rem;
      padding: 28px;
      -webkit-box-shadow: 0px 0px 22px -13px rgba(0,0,0,0.39);
      -moz-box-shadow: 0px 0px 22px -13px rgba(0,0,0,0.39);
      box-shadow: 0px 0px 22px -13px rgba(0,0,0,0.39);  
  }

  .radioFacture {
      padding-left: 7%;
  }

  .factureTitle {
    font-size: 12px;
    color: #314294;
    font-family: 'Neo Bold';
  }

  .total-facture {
      color: #FFB613;
      font-size: 14px;
      font-family: 'Neo Regular';
  }

  .payer-avant {
      font-size: 11px;
      color: #D23030;
      font-family: 'Neo Light';
      text-align: -webkit-right;
  }

  .btn-primary:focus {
      color: #ffffff !important;
      background-color: #58B795 !important;
      border-color: #58B795 !important;
      cursor: pointer;
    }

  .btn-primary:active {
    color: #fff !important;
    background-color: #58B795 !important;
    border-color: #fff !important;
    cursor: pointer;
  }

  .voir-facture-btn:hover {
    width: 80%;
    border-radius: 10rem;
    background-color: white;
    color: #58B795;
    border: 1px solid #58B795;
    cursor: pointer;
  }

  .voir-facture-btn {
    width: 80%;
    border-radius: 10rem;
    background-color: #58B795;
    color: white;
    border: 1px solid #58B795;
    cursor: pointer;
    font-family: 'Neo Light';
    font-size: 13px;
  }
  
  .facture-card {
      background-color: white;
      border-radius: 1rem;
      padding: 28px;
      -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); 
  }

  .leftCardMiniTitle {
    font-size: 15px;
    color: black;
    font-family: 'Neo Regular';
  }

  .leftCardTitle {
      font-size: 25px;
      color: #314294;
      font-family: 'Neo Bold';
  }

  /* ./LEFT CARD */
 
  .image-pay {
      width: 100%;
      background: rgba(49, 66, 148,1);
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

    .selectAllText {
      color: black;
      font-size: 20px;
      font-family: 'Neo Regular';
    }

    /* RIGHT CARD */
      /* Custom Checkbox */
        .butTypeContainer {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 20px;
          font-family: 'Neo Regular';
          color: white;
          font-weight: 200;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
        
        /* Hide the browser's default radio button */
        .butTypeContainer input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
        }
        
        /* Create a custom radio button */
        .btnCheckmark {
          position: absolute;
          top: 6px;
          left: 0;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          border: 1px solid #314294;
          background-color: transparent;
        }
        
        /* On mouse-over, add a grey background color */
        .butTypeContainer:hover input ~ .btnCheckmark {
          background-color: #ccc;
        }
        
        /* When the radio button is checked, add a blue background */
        .butTypeContainer input:checked ~ .btnCheckmark {
          background-color: transparent;
        }
        
        /* Create the indicator (the dot/circle - hidden when not checked) */
        .btnCheckmark:after {
          content: "";
          position: absolute;
          display: none;
        }
        
        /* Show the indicator (dot/circle) when checked */
        .butTypeContainer input:checked ~ .btnCheckmark:after {
          display: block;
        }
        
        /* Style the indicator (dot/circle) */
        .butTypeContainer .btnCheckmark:after {
            top: 4px;
            left: 4px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #314294;
        }

      .paymentTypeTitle {
        font-size: 18px;
        color: black;
        font-family: 'Neo Bold'; 
      }

      .totalText {
        font-size: 25px;
        color: #314294 ;
        font-family: 'Neo Bold' ;
      }
      
      .totalPrice {
        font-size: 25px;
        color: #FFB613 ;
        font-family: 'Neo Regular' ;

      }

      .totalContainer {
          padding: 20px 0px 20px 0px;
          background-color: white;
          margin-left: -25px;
          margin-right: -28px;
      }

      /* DETAILS FACTURES */
        .hrDetails {
          border-top: 1px solid white;
          margin-left: -25px;
          margin-right: -28px;
          width: 100%;
        }

        .detailsFactureTitle {
            font-size: 13px;
            color: white;
            font-family: 'Neo Regular';
          }
          
          .detailsFacturePrice {
            font-size: 13px;
            color: #314294;
            font-family: 'Neo Regular';
          }
          
          .detailsFactureDate {
            font-size: 13px;
            color: #314294;
            font-family: 'Neo Regular';
            margin-top: -10px;

        }
      /* DETAILS FACTURES */

      .rightCardPhone {
        font-size: 18px;
        color: #314294;
        font-family: 'Neo Regular';
      }

      .rightCardTitle {
          font-size: 20px;
          color: #314294;
          font-family: 'Neo Bold';
      }

      .rightFactureContainer {
        background-color: #58B795;
        margin-top: -40px;
        margin-bottom: 50px;
        border-radius: 1rem;
        padding: 28px 28px 28px 50px;
        /* -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
        box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);    */
      }
    /* RIGHT CARD */


    /* LEFT CARD */

    .scrollableLeft {
      width: 102.1%;
      height: 500px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 10px 0px 50px 0px;
    }
    .scrollableRight {
      width: 100%;
      height: 250px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 10px 10px 50px 16px;
    }
    
    .leftFactureContainer {
        z-index: 1; 
        left: 30px;
        background-color: white;
        margin-top: -40px;
        margin-bottom: 50px;
        border-radius: 1rem;
        padding: 28px;
        -webkit-box-shadow: 0px 0px 22px -13px rgba(0,0,0,0.39);
        -moz-box-shadow: 0px 0px 22px -13px rgba(0,0,0,0.39);
        box-shadow: 0px 0px 22px -13px rgba(0,0,0,0.39);    
    }

    .radioFacture {
        padding-left: 7%;
    }

    .factureTitle {
      font-size: 15px;
      color: #314294;
      font-family: 'Neo Bold';
    }

    .total-facture {
        color: #FFB613;
        font-size: 20px;
        font-family: 'Neo Regular';
    }

    .payer-avant {
        font-size: 12px;
        color: #D23030;
        font-family: 'Neo Light';
        text-align: -webkit-right;
    }

    .btn-primary:focus {
        color: #ffffff !important;
        background-color: #58B795 !important;
        border-color: #58B795 !important;
        cursor: pointer;
      }
  
    .btn-primary:active {
      color: #fff !important;
      background-color: #58B795 !important;
      border-color: #fff !important;
      cursor: pointer;
    }
  
    .voir-facture-btn:hover {
      width: 60%;
      border-radius: 10rem;
      background-color: white;
      color: #58B795;
      border: 1px solid #58B795;
      cursor: pointer;
    }
  
    .voir-facture-btn {
      width: 60%;
      border-radius: 10rem;
      background-color: #58B795;
      color: white;
      border: 1px solid #58B795;
      cursor: pointer;
      font-family: 'Neo Light';
    }
    
    .facture-card {
        background-color: white;
        border-radius: 1rem;
        padding: 28px;
        -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);  
    }

    .leftCardMiniTitle {
      font-size: 18px;
      color: black;
      font-family: 'Neo Regular';
    }

    .leftCardTitle {
        font-size: 30px;
        color: #314294;
        font-family: 'Neo Bold';
    }

    /* ./LEFT CARD */
   
    .image-pay {
        width: 100%;
        background: rgba(49, 66, 148,1);
    }
}

