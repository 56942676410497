/* Extra small devices (portrait phones, less than 576px)  */
@media (max-width: 575.98px) {  
    .logo-splash{
      width: 150px;
      height: auto;
    }  
    .background-splash{
      margin: auto;
      text-align: center;
      background-color: #314294 ;
      height: -webkit-fill-available;
      display: flex;
  }  
  }
  
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) and (max-width: 767.98px) {  
    .logo-splash{
      width: 200px;
      height: auto;
    } 
    .background-splash{
      margin: auto;
      text-align: center;
      background-color: #314294 ;
      height: -webkit-fill-available;
      display: flex;
  }  
  }
  
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991.98px) { 
      .logo-splash{
        width: 200px;
        height: auto;
      }  
      .background-splash{
        margin: auto;
        text-align: center;
        background-color: #314294;
        height: -webkit-fill-available;
        display: flex;
    }  
   }
  
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) and (max-width: 1199.98px) {  
    .logo-splash{
      width: 200px;
      height: auto;
    }   
    .background-splash{
      margin: auto;
      text-align: center;
      background-color: #314294 ;
      height: -webkit-fill-available;
      display: flex;
  }  
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
        
      .logo-splash{
        width: 200px;
        height: auto;
      }  
      .background-splash{
        margin: auto;
        text-align: center;
        background-color: #314294 ;
        height: -webkit-fill-available;
        display: flex;
    }    
  }

  