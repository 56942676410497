.logoutBtn {
  color: #314294!important;
  font-size: 20px;
  font-family: 'Neo Light';
  text-decoration: none!important;
  cursor: pointer;
}

.logoutBtn:hover{
  color: #58B795!important;
  cursor: pointer;
}

.backBtn {
  border-radius: 25px;
  width: 12%;
  background-color: #58B795;
  color: #fff;
  font-weight: 800;
  font-size: 12px;
  text-align: center;
  border: 1px solid #58B795;
  margin-top: 4%!important; 
  margin-left: 5%;
}
/* .backBtn:hover{
  background-color: #fff;
  border-radius: 25px;
  border: 1px solid #58B795;
  color: #58B795;  
} */



/* Extra small devices (portrait phones, less than 576px)  */
@media (max-width: 575.98px) {  
  .logo-header{
    width: 120px;
    margin-top: 14px;
    margin-bottom: 14px;
    margin-left: -105%!important;
  }  
  .backBtn {
    border-radius: 25px;
    width: 45%;
    background-color: #58B795;
    color: #fff;
    font-weight: 800;
    font-size: 12px;
    text-align: center;
    border: 1px solid #58B795;
    margin-top: 15%!important;
    margin-left: 5%;

  } 
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {  
  .logo-header{
    width: 120px;
    margin-top: 14px;
    margin-bottom: 14px;
    margin-left: -105%!important;

  } 
  .backBtn {
    border-radius: 25px;
    width: 22%;
    background-color: #58B795;
    color: #fff;
    font-weight: 800;
    font-size: 12px;
    text-align: center;
    border: 1px solid #58B795;
    margin-top: 7%!important;
    margin-left: 5%;

  } 
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    .logo-header{
      width: 120px;
      margin-top: 14px;
      margin-bottom: 14px;
    margin-left: -105%!important;

    }  
    .backBtn {
      border-radius: 25px;
      width: 18%;
      background-color: #58B795;
      color: #fff;
      font-weight: 800;
      font-size: 12px;
      text-align: center;
      border: 1px solid #58B795;
      margin-top: 4%!important; 
      margin-left: 5%;
    } 
 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {  
  .logo-header{
    width: 120px;
    margin-top: 14px;
    margin-bottom: 14px;
    margin-left: -105%!important;

  }   
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
      
    .logo-header{
      width: 140px;
      margin-top: 14px;
      margin-bottom: 14px;
      margin-left: -105%!important;

    }     
    
}

@media (min-width: 1400px) {

  .backBtn {
    border-radius: 25px;
    width: 12%;
    background-color: #58B795;
    color: #fff;
    font-weight: 800;
    font-size: 12px;
    text-align: center;
    border: 1px solid #58B795;
    margin-top: 3%!important; 
    margin-left: 5%;
  } 
}



