/* Extra small devices (portrait phones, less than 576px)  */
/* @media (max-width: 768px) {   */
  @media (max-width: 575.98px) {  
  .errorMsg {
    color : #FF5722 ;
    text-align: center;
    margin: 0px 0px 7px 0px;
  }    
  .contactImage {
    width: 60%;
  }
  
  .contactImageTitle {
    font-size: 20px;
    color: black;
    text-align: center;
    font-family: 'Neo Bold';
  }
  
  .contactPhoneTitle {
    font-size: 15px;
    color: black;
    text-align: center;
    font-family: 'Neo Bold';
  }

  .contactImageDesc {
    font-size: 15px;
    color: #58B795;
    text-align: center;
    font-family: 'Neo Regular';
  }

  /* FORM */       
    .btn-light:focus {
      color: #ffffff !important;
      background-color: #F37121 !important;
      border-color: #FFB613 !important;
      cursor: pointer;
    }

  .btn-light:active {
    color: #fff !important;
    background-color: #58B795 !important;
    border-color: #fff !important;
    cursor: pointer;
  }

  .submitButton:hover {
    width: 115%;
    border-radius: 10rem;
    background-color: white;
    color: #F37121;
    border: 1px solid #F37121;
    cursor: pointer;
  }
  
  .submitButton {
    width: 115%;
    border-radius: 10rem;
    background-color: #F37121;
    font-family: 'Neo Light';
    color: white;
    border: 1px solid #FFB613;
    cursor: pointer;
    transition: all 600ms ease-in-out 0s;
  }

  .form-control:focus {
      outline: none !important;
      border:1px solid #FFB613;
      box-shadow: 0 0 10px #719ECE;
  }

  .form-control {
      border-radius: 10rem;
      border: 2px solid #FFB613;
  }

  .authLabel {
      color: #314294;
      font-size: 18px;
      margin-right: -30px;
      font-family: 'Neo Regular';
      text-align: left;
  }

  .formTitle {
      color: #58B795; 
      font-size: 25px;
      font-family: 'Neo Bold';
  }

  .formRadiosContainer {

  }

  .radioButtonContainer {
      margin-right: 10px;
  }

  /* Custom Checkbox */
    .containerBut {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 18px;
      color: #314294;
      font-weight: 200;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    
    /* Hide the browser's default radio button */
    .containerBut input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    
    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 4px;
      left: 4px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 1px solid #314294;
      background-color: transparent;
    }
    
    /* On mouse-over, add a grey background color */
    .containerBut:hover input ~ .checkmark {
      background-color: #ccc;
    }
    
    /* When the radio button is checked, add a blue background */
    .containerBut input:checked ~ .checkmark {
      background-color: transparent;
    }
    
    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    
    /* Show the indicator (dot/circle) when checked */
    .containerBut input:checked ~ .checkmark:after {
      display: block;
    }
    
    /* Style the indicator (dot/circle) */
    .containerBut .checkmark:after {
        top: 4px;
        left: 4px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #314294;
    }


  /* ./FORM */

  .authContainer {
      background-color: #CBCFE4;
      padding: 50px;
      border-bottom-right-radius: 2rem;
      border-bottom-left-radius: 2rem;
      margin-bottom: 30px;
  }

  .image-paiment{
      width: 100%;
      margin-top: -6px;
      margin-bottom: -6px;
  }
  .authLabel {
    color: #314294;
    font-size: 15px;
    margin-right: -30px;
    font-family: 'Neo Regular';
    text-align: center;
}
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {  
  .contactImageTitle {
    font-size: 25px;
    color: black;
    text-align: center;
    font-family: 'Neo Bold';
  }

  .contactPhoneTitle {
    font-size: 18px;
    color: black;
    text-align: center;
    font-family: 'Neo Bold';
  }

  .contactImageDesc {
    font-size: 15px;
    color: #58B795;
    text-align: center;
    font-family: 'Neo Regular';
  }


  /* FORM */       
    .btn-light:focus {
      color: #ffffff !important;
      background-color: #F37121 !important;
      border-color: #FFB613 !important;
      cursor: pointer;
    }

  .btn-light:active {
    color: #fff !important;
    background-color: #58B795 !important;
    border-color: #fff !important;
    cursor: pointer;
  }

  .submitButton:hover {
    width: 66%;
    border-radius: 10rem;
    background-color: white;
    color: #F37121;
    border: 1px solid #F37121;
    cursor: pointer;
  }

  .submitButton {
    width: 66%;
    border-radius: 10rem;
    background-color: #F37121;
    color: white;
    border: 1px solid #F37121;
    font-family: 'Neo Light';
    cursor: pointer;
    transition: all 600ms ease-in-out 0s;
  }

  .form-control:focus {
      outline: none !important;
      border:1px solid #FFB613;
      box-shadow: 0 0 10px #719ECE;
  }

  .form-control {
      border-radius: 10rem;
      border: 2px solid #FFB613;
  }

  .authLabel {
      color: #314294;
      font-size: 16px;
      margin-right: -30px;
      font-family: 'Neo Regular';
      text-align: center;
  }

  .formTitle {
      color: #58B795; 
      font-size: 26px;
      font-family: 'Neo Bold';
  }

  .formRadiosContainer {

  }

  .radioButtonContainer {
      margin-right: 20px;
  }

  /* Custom Checkbox */
    .containerBut {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 18px;
      color: #314294;
      font-family: 'Neo Regular';
      font-weight: 200;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    
    /* Hide the browser's default radio button */
    .containerBut input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    
    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 6px;
      left: 0;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 2px solid #314294;
      background-color: transparent;
    }
    
    /* On mouse-over, add a grey background color */
    .containerBut:hover input ~ .checkmark {
      background-color: #ccc;
    }
    
    /* When the radio button is checked, add a blue background */
    .containerBut input:checked ~ .checkmark {
      background-color: transparent;
    }
    
    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    
    /* Show the indicator (dot/circle) when checked */
    .containerBut input:checked ~ .checkmark:after {
      display: block;
    }
    
    /* Style the indicator (dot/circle) */
    .containerBut .checkmark:after {
        top: 3px;
        left: 3px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #314294;
    }


  /* ./FORM */

  .authContainer {
      background-color: #CBCFE4;
      padding: 50px;
      border-bottom-right-radius: 2rem;
      border-bottom-left-radius: 2rem;
      margin-bottom: 30px;
  }

  .image-paiment{
      width: 100%;
      margin-top: -6px;
      margin-bottom: -6px;
  }
  .errorMsg {
    color : #FF5722 ;
    text-align: center;
    margin: 0px 50px 7px 0px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
  .contactImageTitle {
    font-size: 25px;
    color: black;
    text-align: center;
    font-family: 'Neo Bold';
  }

  .contactPhoneTitle {
    font-size: 18px;
    color: black;
    text-align: center;
    font-family: 'Neo Bold';
  }

  .contactImageDesc {
    font-size: 15px;
    color: #58B795;
    text-align: center;
    font-family: 'Neo Regular';
  }


  /* FORM */       
    .btn-light:focus {
      color: #ffffff !important;
      background-color: #F37121 !important;
      border-color: #FFB613 !important;
      cursor: pointer;
    }

  .btn-light:active {
    color: #fff !important;
    background-color: #58B795 !important;
    border-color: #fff !important;
    cursor: pointer;
  }

  .submitButton:hover {
    width: 84%;
    border-radius: 10rem;
    background-color: white;
    color: #F37121;
    border: 1px solid #F37121;
    cursor: pointer;
  }

  .submitButton {
    width: 84%;
    border-radius: 10rem;
    background-color: #F37121;
    color: white;
    border: 1px solid #F37121;
    font-family: 'Neo Light';
    cursor: pointer;
    transition: all 600ms ease-in-out 0s;
  }

  .form-control:focus {
      outline: none !important;
      border:1px solid #FFB613;
      box-shadow: 0 0 10px #719ECE;
  }

  .form-control {
      border-radius: 10rem;
      border: 2px solid #FFB613;
  }

  .authLabel {
      color: #314294;
      font-size: 18px;
      margin-right: -30px;
      font-family: 'Neo Regular';
      text-align: center;
  }

  .formTitle {
      color: #58B795; 
      font-size: 30px;
      font-family: 'Neo Bold';
  }

  .formRadiosContainer {

  }

  .radioButtonContainer {
      margin-right: 20px;
  }

  /* Custom Checkbox */
    .containerBut {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 20px;
      color: #314294;
      font-family: 'Neo Regular';
      font-weight: 200;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    
    /* Hide the browser's default radio button */
    .containerBut input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    
    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 6px;
      left: 0;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 2px solid #314294;
      background-color: transparent;
    }
    
    /* On mouse-over, add a grey background color */
    .containerBut:hover input ~ .checkmark {
      background-color: #ccc;
    }
    
    /* When the radio button is checked, add a blue background */
    .containerBut input:checked ~ .checkmark {
      background-color: transparent;
    }
    
    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    
    /* Show the indicator (dot/circle) when checked */
    .containerBut input:checked ~ .checkmark:after {
      display: block;
    }
    
    /* Style the indicator (dot/circle) */
    .containerBut .checkmark:after {
        top: 3px;
        left: 3px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #314294;
    }


  /* ./FORM */

  .authContainer {
      background-color: #CBCFE4;
      padding: 50px;
      border-bottom-right-radius: 2rem;
      border-bottom-left-radius: 2rem;
      margin-bottom: 30px;
  }

  .image-paiment{
      width: 100%;
      margin-top: -6px;
      margin-bottom: -6px;
  }
  .errorMsg {
    color : #FF5722 ;
    text-align: center;
    margin: 0px 50px 7px 0px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {  
    .contactImageTitle {
        font-size: 25px;
        color: black;
        text-align: center;
        font-family: 'Neo Bold';
    }

    .contactPhoneTitle {
      font-size: 18px;
      color: black;
      text-align: center;
      font-family: 'Neo Bold';
    }

    .contactImageDesc {
      font-size: 15px;
      color: #58B795;
      text-align: center;
      font-family: 'Neo Regular';
    }


    /* FORM */       
      .btn-light:focus {
        color: #ffffff !important;
        background-color: #F37121 !important;
        border-color: #FFB613 !important;
        cursor: pointer;
      }

    .btn-light:active {
      color: #fff !important;
      background-color: #58B795 !important;
      border-color: #fff !important;
      cursor: pointer;
    }

    .submitButton:hover {
      width: 84%;
      border-radius: 10rem;
      background-color: white;
      color: #F37121;
      border: 1px solid #F37121;
      cursor: pointer;
    }

    .submitButton {
      width: 84%;
      border-radius: 10rem;
      background-color: #F37121;
      color: white;
      border: 1px solid #F37121;
      font-family: 'Neo Light';
      cursor: pointer;
      transition: all 600ms ease-in-out 0s;
    }

    .form-control:focus {
        outline: none !important;
        border:1px solid #FFB613;
        box-shadow: 0 0 10px #719ECE;
    }

    .form-control {
        border-radius: 10rem;
        border: 2px solid #FFB613;
    }

    .authLabel {
        color: #314294;
        font-size: 18px;
        margin-right: -30px;
        font-family: 'Neo Regular';
        text-align: center;
    }

    .formTitle {
        color: #58B795; 
        font-size: 30px;
        font-family: 'Neo Bold';
    }

    .formRadiosContainer {

    }

    .radioButtonContainer {
        margin-right: 20px;
    }

    /* Custom Checkbox */
      .containerBut {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 20px;
        color: #314294;
        font-weight: 200;
        font-family: 'Neo Regular';
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      /* Hide the browser's default radio button */
      .containerBut input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      
      /* Create a custom radio button */
      .checkmark {
        position: absolute;
        top: 6px;
        left: 0;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 2px solid #314294;
        background-color: transparent;
      }
      
      /* On mouse-over, add a grey background color */
      .containerBut:hover input ~ .checkmark {
        background-color: #ccc;
      }
      
      /* When the radio button is checked, add a blue background */
      .containerBut input:checked ~ .checkmark {
        background-color: transparent;
      }
      
      /* Create the indicator (the dot/circle - hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      /* Show the indicator (dot/circle) when checked */
      .containerBut input:checked ~ .checkmark:after {
        display: block;
      }
      
      /* Style the indicator (dot/circle) */
      .containerBut .checkmark:after {
          top: 3px;
          left: 3px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #314294;
      }


    /* ./FORM */

    .authContainer {
        background-color: #CBCFE4;
        padding: 50px;
        border-bottom-right-radius: 2rem;
        border-bottom-left-radius: 2rem;
        margin-bottom: 30px;
    }

    .image-paiment{
        width: 100%;
        margin-top: -6px;
        margin-bottom: -6px;
    }
    .errorMsg {
      color : #FF5722 ;
      text-align: center;
      margin: 0px 50px 7px 0px;
    }
}


/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

    .contactImageTitle {
        font-size: 25px;
        color: black;
        text-align: center;
        font-family: 'Neo Bold';
    }

    .contactPhoneTitle {
      font-size: 18px;
      color: black;
      text-align: center;
      font-family: 'Neo Bold';
    }

    .contactImageDesc {
       font-size: 15px;
       color: #58B795;
       text-align: center;
       font-family: 'Neo Regular';
    }

  
    /* FORM */       
      .btn-light:focus {
        color: #ffffff !important;
        background-color: #F37121 !important;
        border-color: #FFB613 !important;
        cursor: pointer;
      }

     .btn-light:active {
      color: #fff !important;
      background-color: #58B795 !important;
      border-color: #fff !important;
      cursor: pointer;
    }
 
    .submitButton:hover {
      width: 87%;
      border-radius: 10rem;
      background-color: white;
      color: #F37121;
      border: 1px solid #F37121;
      cursor: pointer;
    }
    
    .submitButton {
      width: 87%;
      border-radius: 10rem;
      background-color: #F37121;
      color: white;
      border: 1px solid #F37121;
      font-family: 'Neo Light';
      cursor: pointer;
      transition: all 600ms ease-in-out 0s;
    }
    
    .form-control:focus {
        outline: none !important;
        border:1px solid #FFB613;
        box-shadow: 0 0 10px #719ECE;
    }

    .form-control {
        border-radius: 10rem;
        border: 2px solid #FFB613;
    }

    .authLabel {
        color: #314294;
        font-size: 18px;
        margin-right: -30px;
        font-family: 'Neo Regular';
        text-align: left;
    }
    
    .formTitle {
        color: #58B795; 
        font-size: 30px;
        font-family: 'Neo Bold';
    }

    .formRadiosContainer {

    }

    .radioButtonContainer {
        margin-right: 20px;
    }

    /* Custom Checkbox */
      .containerBut {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 20px;
        color: #314294;
        font-family: 'Neo Regular';
        font-weight: 200;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      /* Hide the browser's default radio button */
      .containerBut input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      
      /* Create a custom radio button */
      .checkmark {
        position: absolute;
        top: 6px;
        left: 0;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 2px solid #314294;
        background-color: transparent;
      }
      
      /* On mouse-over, add a grey background color */
      .containerBut:hover input ~ .checkmark {
        background-color: #ccc;
      }
      
      /* When the radio button is checked, add a blue background */
      .containerBut input:checked ~ .checkmark {
        background-color: transparent;
      }
      
      /* Create the indicator (the dot/circle - hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      /* Show the indicator (dot/circle) when checked */
      .containerBut input:checked ~ .checkmark:after {
        display: block;
      }
      
      /* Style the indicator (dot/circle) */
      .containerBut .checkmark:after {
          top: 3px;
          left: 3px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #314294;
      }


    /* ./FORM */

    .authContainer {
        background-color: #CBCFE4;
        padding: 50px;
        border-bottom-right-radius: 2rem;
        border-bottom-left-radius: 2rem;
        margin-bottom: 30px;
    }

    .image-paiment{
        width: 100%;
        margin-top: -6px;
        margin-bottom: -6px;
    }
    .errorMsg {
      color : #FF5722 ;
      text-align: center;
      margin: 0px 50px 7px 0px;
    }

}

.btnPopup {
  width: 30%;
  border-radius: 10rem;
  background-color: #F37121;
  color: white;
  border: 1px solid #F37121;
  font-family: 'Neo Light';
  padding: 4px 0px 4px 0px;
  cursor: pointer;
  transition: all 600ms ease-in-out 0s;
}

.btnPopup:hover {
  border-radius: 10rem;
  background-color: white;
  color: #F37121;
  border: 1px solid #F37121;
  transition: all 600ms ease-in-out 0s;
}